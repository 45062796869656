import ClientReviewsSection from '@/components/ClientReviewsSection';
import DistinctionSection from '@/components/DistinctionSection';
import HelpSection from '@/components/HelpSection';
import HeroSection from '@/components/HeroSection';
import HowWorksSection from '@/components/HowWorksSection';
import InterestedSection from '@/components/InterestedSection';
import OurOfferSection from '@/components/OurOfferSection';
import TermsSection from '@/components/TermsSection';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>Strona główna</title>
        <meta name="description" content="Strona główna firmy DuoInvest" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HeroSection />
      <OurOfferSection />
      <HelpSection />
      <TermsSection />
      <DistinctionSection />
      <ClientReviewsSection />
      <HowWorksSection />
      <InterestedSection />
    </>
  );
}
